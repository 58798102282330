import { Controller } from '@hotwired/stimulus';
import Imask from 'imask';

export default class ImaskController extends Controller {
  static values = { type: String };

  initialize() {
    switch (this.element.dataset.type) {
      case 'dateRange':
        Imask(this.element, {
          autofix: true,
          lazy: true,
          mask: '0000 - 0000'
        });
        break;
    }
  }
}
