const particlesConfig = {
  background: {
    color: "#660004"
  },
  fullScreen: {
    enable: true,
    zIndex: -2,
  },
  particles: {
    number: {
      value: 80,
      density: {
        enable: true,
        width: 800
      }
    },
    color: {
      value: "#FFFFFF"
    },
    shape: {
      type: "polygon",
      options: {
        polygon: {
          sides: 6
        },
      }
    },
    opacity: {
      value: {
        min: 0.1,
        max: 0.3
      },
      animation: {
        enable: true,
        speed: 0.1,
        sync: false,
      }
    },
    size: {
      value: {
        min: 0,
        max: 6,
      },
      animation: {
        enable: true,
        sync: false,
      }
    },
    links: {
      enable: true,
      distance: 150,
      color: "#FFF",
      opacity: 0.2,
      width: 1
    },
    move: {
      enable: true,
      speed: 1,
      direction: "none",
      random: false,
      straight: false,
      outMode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200
      }
    }
  },
  interactivity: {
    detectOn: "window",
    events: {
      onHover: {
        enable: true,
        mode: "grab"
      },
      resize: true
    },
    modes: {
      grab: {
        distance: 200,
        links: {
          opacity: 0.3
        }
      }
    }
  },
  retina_detect: true
};


export default particlesConfig;
