import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'

export function uppyInstance({ allowedFileTypes, presignUrl, autoProceed = true, minNumberOfFiles = 0, maxNumberOfFiles = 10, maxFileSize = 6000000 }) {
  const uppy = new Uppy({
    autoProceed,
    restrictions: {
      allowedFileTypes,
      minNumberOfFiles,
      maxNumberOfFiles,
      maxFileSize,
      presignUrl
    },
  })

  uppy.use(AwsS3, {
    companionUrl: presignUrl,
  })

  return uppy
}

export function uploadedFileData(file, response) {
  return JSON.stringify(response.body)
}
