import { Controller } from '@hotwired/stimulus';
import Webcam from '@uppy/webcam';
import { patch } from '@rails/request.js'

import { uppyInstance } from '~/uppy'

export default class extends Controller {
  static targets = [ 'input' ]
  static values = {
    types: { type: Array },
    presignUrl: { type: String },
    postUrl: { type: String },
    redirectUrl: { type: String },
    minNumberOfFiles: { type: Number,  default: 0 },
    maxNumberOfFiles: { type: Number,  default: 1 },
    maxFileSize: { type: Number, default: 6000000 },
  }

  connect() {
    this.uppy = uppyInstance({
      types: this.typesValue,
      presignUrl: this.presignUrlValue,
      postUrl: this.postUrlValue,
      minNumberOfFiles: this.minNumberOfFilesValue,
      maxNumberOfFiles: this.maxNumberOfFilesValue,
      maxFileSize: this.maxFileSizeValue
    })
    .use(Webcam, {
      target: this.element,
      showRecordingLength: true,
      mobileNativeCamera: false,
      mirror: false,
      modes: ['video-audio']
    })
    .on('upload-success', async (file, response) => {
      const uploadedFileData = JSON.stringify({
        id: file.meta['key'].match(/^cache\/(.+)/)[1],
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        }
      })

      const res = await patch(this.postUrlValue, {
        body: {
          story: {
            type: 'record',
            digital_content: uploadedFileData
          }
        }
      })

      if (res.ok) {
        window.location = this.redirectUrlValue
      }
    });

    window.uppy = this.uppy; // useful for testing

    return this.uppy
  }

  disconnect() {
    window.uppy = null;
    this.uppy.close()
  }
}
