import { Controller } from '@hotwired/stimulus';

import { tsParticles } from "@tsparticles/engine";
import { loadFull } from "tsparticles";

import particlesConfig from '../../particlesjs-config';

export default class extends Controller {
  connect() {
    this.#loadParticles(particlesConfig);
  }

  async #loadParticles(options) {
    await loadFull(tsParticles);

    tsParticles.load({ element: this.element, options: options });
  }
}
