import { Controller } from '@hotwired/stimulus';
import Dashboard from '@uppy/dashboard';
import { patch } from '@rails/request.js'

import { uppyInstance } from '~/uppy'

export default class extends Controller {
  static targets = [ 'input' ]
  static values = {
    presignUrl: { type: String },
    postUrl: { type: String },
    redirectUrl: { type: String },
    minNumberOfFiles: { type: Number,  default: 0 },
    maxNumberOfFiles: { type: Number,  default: 1 },
    maxFileSize: { type: Number, default: 6000000 },
  }

  connect() {
    this.uppy = uppyInstance({
      allowedFileTypes: ['audio/*', 'video/*'],
      presignUrl: this.presignUrlValue,
      postUrl: this.postUrlValue,
      autoProceed: false,
      minNumberOfFiles: this.minNumberOfFilesValue,
      maxNumberOfFiles: this.maxNumberOfFilesValue,
      maxFileSize: this.maxFileSizeValue
    })
    .use(Dashboard, {
      target: this.element,
      inline: true,
      height: 400,
      proudlyDisplayPoweredByUppy: false,
      singleFileFullScreen: true,
      showRemoveButtonAfterComplete: true,
      locale: {
        strings: {
          browseFiles: 'browse file',
          dropPasteFiles: 'Drop file here or %{browseFiles}',
        }
      }
    })
    .on('upload-success', async (file, response) => {
      const uploadedFileData = JSON.stringify({
        id: file.meta['key'].match(/^cache\/(.+)/)[1],
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        }
      })

      const res = await patch(this.postUrlValue, {
        body: {
          story: {
            type: 'uplaod',
            digital_content: uploadedFileData
          }
        }
      })

      if (res.ok) {
        window.location = this.redirectUrlValue
      }
    });

    window.uppy = this.uppy; // useful for testing

    return this.uppy
  }

  disconnect() {
    window.uppy = null;
    this.uppy.close()
  }
}
