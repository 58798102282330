import Flatpickr from 'stimulus-flatpickr';

export default class extends Flatpickr {
  static values = {
    type: { type: String, default: 'date' }
  };

  initialize() {
    if (this.typeValue === 'date') {
      this.config = {
        altInput: true,
        altFormat: 'M j, Y',
      };
    }
  }
}
