import { registerControllers } from 'stimulus-vite-helpers';
import {
  Alert,
  Dropdown,
  Toggle,
  Modal
} from 'tailwindcss-stimulus-components';
import TextareaAutogrow from 'stimulus-textarea-autogrow';

import { stimulus } from '~/init';

const controllers = import.meta.glob('./**/*_controller.js', { eager: true });

stimulus.register('alert', Alert)
stimulus.register('dropdown', Dropdown);
stimulus.register('modal-sync', Modal);
stimulus.register('toggle', Toggle);
stimulus.register('textarea-autogrow', TextareaAutogrow);

registerControllers(stimulus, controllers);
